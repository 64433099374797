import { Component, OnInit, Input } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-steps-afiliacion',
  templateUrl: './steps-afiliacion.component.html',
  styleUrls: ['./steps-afiliacion.component.css']
})
export class StepsAfiliacionComponent implements OnInit {

  @Input() stepActivo:string;

  constructor() {
  }

  ngOnInit(): void {
    this.cambiarEstadoSteepes(this.stepActivo);
  }

  async cambiarEstadoSteepes(nomEstado) {
    // Reiniciar todos los pasos y líneas a su estado inicial
    $(".md-step").removeClass("active");
    $(".md-step-bar-left").removeClass("md-step-bar-left-active");
    $(".md-step-bar-right").removeClass("md-step-bar-right-active");

    switch (nomEstado) {
        case "INFO_PERSONAL":
            await this.delay(3000);
            $("#stepInfoPersonal").addClass("active");
            break;

        case "INFO_COMERCIAL":
          await this.delay(1000)
            $("#stepInfoPersonal").addClass("active");
            $("#stepInfoComercial").addClass("active");
            $("#lineaInfoPerDer").addClass("md-step-bar-right-active");
            $("#lineaInfComIzq").addClass("md-step-bar-left-active");
            break;

        case "INFO_DOCS":
            await this.delay(1000);
            $("#stepInfoPersonal").addClass("active");
            $("#stepInfoComercial").addClass("active");
            $("#stepInfoDocs").addClass("active");
            $("#lineaInfoPerDer").addClass("md-step-bar-right-active");
            $("#lineaInfComIzq").addClass("md-step-bar-left-active");
            $("#lineaInfComDer").addClass("md-step-bar-right-active");
            $("#lineaDocIzq").addClass("md-step-bar-left-active");
            break;

        case "INFO_AUTORIZACIONES":
          await this.delay(1000);
            $("#stepInfoPersonal").addClass("active");
            $("#stepInfoComercial").addClass("active");
            $("#stepInfoDocs").addClass("active");
            $("#stepAutorizacion").addClass("active");
            $("#lineaInfoPerDer").addClass("md-step-bar-right-active");
            $("#lineaInfComIzq").addClass("md-step-bar-left-active");
            $("#lineaInfComDer").addClass("md-step-bar-right-active");
            $("#lineaDocIzq").addClass("md-step-bar-left-active");
            $("#lineaDocDer").addClass("md-step-bar-right-active");
            $("#lineaAutoIzq").addClass("md-step-bar-left-active");
            break;

        default:
            $("#stepInfoPersonal").addClass("active");
            break;
    }
}
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

}
