

<div id="menuPc mb-4">
    <div class="p-grid inicioMenu">
        <div class="p-col-2 d-flex align-items-center">
            <img src="./assets/app/financiar_logo.png" class="logoBar" />
        </div>
        <div class="p-col-10">
            <div class="p-grid backMenu">
                <div class="p-col-3 colTxt" (click)="redirigirRuta('sencilla', '/admin/home-administrador', null)">
                    <p class="txtBar">Inicio
                        <!-- <i class="pi pi-home iconHome"></i> -->
                    </p>
                </div>

                <div class="p-col-3 colTxt" (click)="redirigirRuta('sencilla', '/admin/solicitudes-afiliacion', null)">
                    <p class="txtBar">Solicitudes</p>
                </div>

                <div class="p-col-3 colTxt">
                    <p class="txtBar">Administrador</p>
                </div>

                <div class="p-col-3 colTxt">
                    <p class="txtBar cerrar" (click)="cerrarSesion()">Cerrar sesión</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="menuMovil">
    <p-button type="button" icon="pi pi-bars"></p-button>
</div>
