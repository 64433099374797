// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // URL_SERVICE: 'http://localhost:8080/WSRestApi2Bank/APIRest/',               // Desarrollo


    URL_SERVICE: 'https://tsafiliacion2bankbe.ebsproductodigital.com/WSRestApi2BankAfi/APIRest/', //pruebas
    // URL_SERVICE: 'http://150.136.51.89:8080/WSRestApiFinanciar/APIRest/', //pruebas

    URL_FTP: 'http://190.85.106.122:8082/FTPCooperagro/'

    // export NODE_OPTIONS=--openssl-legacy-provider

};
/*  ng build --output-hashing=all --base-href /FinanciarAfiliacion/
 * ng build --output-hashing=all --prod --base-href /FinanciarAfiliacion/
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
//
